import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Helpers
import get from 'lodash/get';
import map from 'lodash/map';
import useContentful from 'src/helpers/hooks/useContentful';
// Components
import PageLayout from 'src/components/Layout';
import {
  Typography, Divider, Image as AntdImage, Grid,
} from 'antd';
import Image from 'src/components/Image';
import Masonry from 'react-masonry-css';
import PageTitle from '../components/PageTitle';

const { Title } = Typography;
const { useBreakpoint } = Grid;

export const query = `
  {
    gallery(id: "4iarjfTHRuUi2sbVLHZPnG") {
      pageHeader {
        pageTitle
        pagePhotoCollection {
          items {
            fileName
            url
            width
            height
          }
        }
      }
      galleryCollection {
        items {
          galleryTitle
          galleryPhotosCollection {
            items {
              fileName
              url
              width
              height
            }
          }
        }
      }
    }
  }
`;

const GalleryIndex = function ({ location }) {
  const { lg } = useBreakpoint();

  const { data, loading } = useContentful(query);
  const galleries = get(data, 'gallery.galleryCollection.items') || [];
  const title = get(data, 'gallery.pageHeader.pageTitle');
  const mainPhoto = get(data, 'gallery.pageHeader.pagePhotoCollection.items');

  return (
    <PageLayout
      loading={loading}
      location={location}
      title={title}
      photo={mainPhoto}
    >
      <div className='gallery'>
        {lg && (
          <PageTitle
            title={title}
            style={{
              textAlign: 'center',
            }}
          />
        )}
        {map(galleries, (gallery, index) => (
          <Fragment key={gallery.galleryTitle}>
            <Title level={4}>{gallery.galleryTitle}</Title>
            <AntdImage.PreviewGroup>
              <Masonry
                breakpointCols={2}
                className='my-masonry-grid'
                columnClassName='my-masonry-grid_column'
              >
                {map(get(gallery, 'galleryPhotosCollection.items'), (collection) => (
                  <Image
                    src={collection.url}
                    {...collection}
                    preview
                  />
                ))}
              </Masonry>
            </AntdImage.PreviewGroup>
            {index + 1 < galleries.length && <Divider />}
          </Fragment>
        ))}
      </div>
    </PageLayout>
  );
};

GalleryIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default GalleryIndex;
